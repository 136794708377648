<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'field' }">Field Management</router-link>
        </li>
        <li class="breadcrumb-item active">Add Fields</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row">
        <div class="col-md-11">
          <h2 class="title2">Add Accessory</h2>
        </div>
        <div class="col-md-1 text-right">
          <router-link :to="{ name: 'field' }" class="btn btn-outline-primary"
            >Back</router-link
          >
        </div>
      </div>
      <div v-if="this.$store.state.loader">
        <loader
          object="#f74b3d"
          color1="#ffffff"
          color2="#17fd3d"
          size="5"
          speed="2"
          bg="#343a40"
          objectbg="#999793"
          opacity="80"
          disableScrolling="false"
          name="spinning"
        ></loader>
      </div>
      <form v-else @submit.prevent="onSubmit">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header"></div>
              <div class="card-body">
                <div class="mb-3">
                  <label class="form-label">Field Label Name</label>
                  <input
                    type="text"
                    placeholder="Add Field Label"
                    class="form-control"
                    v-model="$v.form.label.$model"
                    :validator="$v.form.label"
                  />
                  <span class="error" v-if="$v.form.label.$error == true"
                    >Label name is required</span
                  >
                </div>
                <div class="mb-3">
                  <label class="form-label">Fields Type</label>
                  <b-form-select
                    @onselect="type()"
                    class="form-control"
                    :validator="$v.form.fields"
                    v-model="selected"
                    :options="options"
                  >
                  </b-form-select>
                  <span class="error" v-if="$v.form.fields.$error == true"
                    >Fields Type is required</span
                  >
                </div>
                <div class="text-right">
                  <button type="submit" class="btn btn-outline-primary space">
                    Save
                  </button>
                  <router-link
                    :to="{ name: 'field' }"
                    class="btn btn-outline-primary"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { required, sameAs } from "vuelidate/lib/validators";
import moment from "moment";
import axios from "axios";
export default {
  data() {
    return {
      form: {
        label: null,
        fields: this.options,
      },
      selected: null,
      options: [
        { value: "Input Type", text: "Input Type" },
        { value: "Select Type", text: "Select Type" },
        { value: "Textarea Type", text: "Textarea Type" },
      ],
    };
  },
  validations: {
    form: {
      label: {
        required,
      },
      fields: {
        required,
      },
    },
  },
  created() {
    this.$store.dispatch("switchLoader", true);
    this.$store.dispatch("loadUsers");
  },
  mounted() {},
  computed: {
    users() {
      return this.$store.state.users.users.map((user) => {
        return {
          value: user.idno,
          text: user.name,
        };
      });
    },
  },
  methods: {
    type() {
      alert(this.selected);
    },
    timeChangeHandler(event, time) {
      if (time == "timein") {
        this.form.timein = event.displayTime;
      } else {
        this.form.timeout = event.displayTime;
      }
    },

    customFormatter(date) {
      return moment(date).format("yyyy-MM-DD");
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      console.log(this.form);
      axios
        .post("/accessories/assign", this.form)
        .catch(({ response }) => {})
        .then(({ data }) => {
          if (data.success) {
            this.flashMessage.success({
              message: data.success,
              time: 3000,
            });
            this.$router.push({ name: "accessories" });
          } else {
            this.flashMessage.error({
              message: data.data.error,
              time: 3000,
            });
          }
        });
    },
    onChangeFileUpload() {
      this.form.image = this.$refs.file.files[0];
    },
  },
};
</script>
